import {
  faAward,
  faCrown,
  faMedal,
  faTrophy,
} from "@fortawesome/pro-duotone-svg-icons"
// @fortawesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf"
// page styles
import pastWinnersDocumentsStyle from "assets/jss/material-kit-pro-react/views/pastWinnersPageSections/pastWinnersDocumentsStyle.jsx"
// core components
import Button from "components/CustomButtons/Button.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import React from "react"
// helpers
import { getEventDate } from "helpers/Functions.jsx"
import moment from "moment"

const currDate = moment().format("YYYY-MM-DD hh:mm:ss")
let pastYear = moment(currDate).year()
const chilimaniaDate = moment(getEventDate("chilimania"))

if (moment(currDate).isSameOrBefore(chilimaniaDate.add(1, "days"))) {
  if (chilimaniaDate.year() - moment(currDate).year() === 0) {
    pastYear = pastYear - 1
  }
}

const useStyles = makeStyles(pastWinnersDocumentsStyle)

export default function PastWinnersDocuments() {
  const classes = useStyles()
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justifyContent="center">
          <GridItem xs={12} sm={10} md={8} className={classes.pastWinnerInfo}>
            <h2 className={classes.title}>{pastYear} Winners</h2>
            <p className={classes.description}>
              Cooks who did not place in any of our three double-blind judging
              categories can use their tickets to find out where they placed.
              Also included are the full results for People’s Choice voting.
              Those who did place in any category can view those results in the
              table of winners displayed below.
            </p>
          </GridItem>
        </GridContainer>
        <GridContainer justifyContent="center">
          <GridItem md={3} sm={6} className={classes.documentsItem}>
            <FontAwesomeIcon
              className={classes.documentIcons}
              icon={faTrophy}
            />
            <h4 className={classes.subtitle}>CASI Chili</h4>
            {/* <Button
              className={classes.documentsButton}
              color="primary"
              disabled
            >
              <PictureAsPdfIcon />
              Not Available
            </Button> */}
          </GridItem>
          <GridItem md={3} sm={6} className={classes.documentsItem}>
            <FontAwesomeIcon className={classes.documentIcons} icon={faAward} />
            <h4 className={classes.subtitle}>Traditional Chili</h4>
            {/* <Button
              className={classes.documentsButton}
              color="primary"
              disabled
            >
              <PictureAsPdfIcon />
              Not Available
            </Button> */}
          </GridItem>
          <GridItem md={3} sm={6} className={classes.documentsItem}>
            <FontAwesomeIcon className={classes.documentIcons} icon={faMedal} />
            <h4 className={classes.subtitle}>Salsa</h4>
            {/* <Button
              className={classes.documentsButton}
              color="primary"
              disabled
            >
              <PictureAsPdfIcon />
              Not Available
            </Button> */}
          </GridItem>
          <GridItem md={3} sm={6} className={classes.documentsItem}>
            <FontAwesomeIcon className={classes.documentIcons} icon={faCrown} />
            <h4 className={classes.subtitle}>People's Choice</h4>
            {/* <Button
              className={classes.documentsButton}
              color="primary"
              disabled
            >
              <PictureAsPdfIcon />
              Not Available
            </Button> */}
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}
